// Stores
import { defineStore } from "pinia";
import { useClusterStore } from "./cluster.store";
// Services
import { accessRuleService } from "@/services/control-plane/rbac/access-rule.service/access-rule.service";
import { projectService } from "@/services/control-plane/project.service/project.service";
import { researcherService } from "@/services/cluster/researcher.service/researcher.service";

// Models
import type { IFilterBy } from "@/models/filter.model";
import type { ILoadProjectsOptions, IProject, ISelectedNodeAffinity } from "@/models/project.model";
import { EClusterVersions, type IClusterProject } from "@/models/cluster.model";
import type { ProjectMetadata } from "@/swagger-models/org-unit-service-client";
//utils
import { enrichScopeEntityWithAccessRules } from "@/utils/rbac.util/access-rule.util/access-rule.util";
import { usePermissionStore } from "@/stores/permissions.store";
import { Action, ResourceType, ScopeType } from "@/swagger-models/authorization-client";
import { orgUnitService } from "@/services/control-plane/org-unit.service/org-unit.service";

export const useProjectStore = defineStore("Project", {
  state: () => ({
    projects: [] as Array<IProject>,
    projectsResources: [] as Array<ProjectMetadata>,
    loading: false as boolean,
    namespaces: {} as Record<string, string | undefined>,
    clusterStore: useClusterStore(),
  }),
  getters: {
    hasProjects(): boolean {
      return this.projects.length > 0;
    },
    projectList(): Array<IProject> {
      return this.projects;
    },
    projectResourceList(): Array<ProjectMetadata> {
      return this.projectsResources;
    },
    projectsNames(): string[] {
      return this.projects.map((project: IProject) => project.name);
    },
  },
  actions: {
    async loadProjects(options: ILoadProjectsOptions, filterBy: IFilterBy = {}): Promise<IProject[]> {
      const { withAccessRules = false, withNamespace = true, clusterId } = options || {};

      try {
        this.loading = true;
        let projects: IProject[] = await projectService.list(clusterId, filterBy);

        if (withAccessRules && usePermissionStore().hasPermission(ResourceType.AccessRules, Action.Read)) {
          const scopeAccessRules = await accessRuleService.getAccessRules({
            scopeType: ScopeType.Project,
          });
          projects = enrichScopeEntityWithAccessRules(projects, scopeAccessRules);
        }

        this.projects = projects;

        if (withNamespace && options.clusterId) {
          await this.loadNamespaces(options.clusterId);
        }
        return projects;
      } finally {
        this.loading = false;
      }
    },
    async loadProjectsMetaData(clusterId: string): Promise<void> {
      this.projectsResources = await orgUnitService.getProjectsMetadata(clusterId);
    },
    async loadNamespaces(clusterId: string): Promise<Record<string, string | undefined> | undefined> {
      const clusterVersion: string | undefined = this.clusterStore.getClusterById(clusterId)?.version;

      if (!clusterVersion) return;
      // Workspaces are enabled only for 2.9 and above.
      // From 2.10 and above the namespace exists in the projects from the backend
      const isNamespaceInProjectStatus: boolean =
        !clusterVersion.startsWith(EClusterVersions.v2_9) && !clusterVersion.startsWith(`v${EClusterVersions.v2_9}`);

      if (!isNamespaceInProjectStatus) {
        const projects: Array<IClusterProject> = await researcherService.getProjects();
        this.namespaces = projects.reduce((acc: Record<string, string | undefined>, project: IClusterProject) => {
          acc[project.name] = project.managedNamespace;
          return acc;
        }, {} as Record<string, string | undefined>);
      } else {
        this.namespaces = this.projects.reduce((acc: Record<string, string | undefined>, project: IProject) => {
          acc[project.name] = project.status?.namespace || undefined;
          return acc;
        }, {} as Record<string, string | undefined>);
      }
      return this.namespaces;
    },
    getNamespaceByProjectId(projectId: number | null): string {
      if (!projectId) return "";
      const projectName: string | undefined = this.projects.find((project: IProject) => +project.id === projectId)?.name;
      if (!projectName) return "";
      return this.namespaces[projectName] || "";
    },
    getInteractiveNodeAffinityByProjectId(projectId: number): ISelectedNodeAffinity[] {
      if (!projectId) return [];
      return (
        this.projects.find((project: IProject) => +project.id === projectId)?.nodeAffinity.interactive.selectedTypes ||
        []
      );
    },
    getTrainingNodeAffinityByProjectId(projectId: number): ISelectedNodeAffinity[] {
      if (!projectId) return [];
      return (
        this.projects.find((project: IProject) => +project.id === projectId)?.nodeAffinity.train.selectedTypes || []
      );
    },
  },
});
