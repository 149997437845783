<template>
  <chart-widget-wrapper
    :options="wrapperOptions"
    body-no-padding
    :loading="loading"
    :error="error"
    @export-csv="exportCsv"
  >
    <div class="resource-utilization-time-range-widget-body row">
      <div class="chart-container col-xl-9 col-10">
        <highcharts v-if="chartOptions" :options="chartOptions" style="width: 100%" ref="chart" key="" />
      </div>
      <div class="average-data col-xl-3 col-2">
        <div class="text-weight-medium q-mb-md">Average utilization</div>
        <div class="metric-by-group">
          <div class="metric-row" v-for="metric in averageMetrics" :key="metric.name">
            <span>{{ metric.name }}</span>
            <span class="metric-value">{{ metric.value }}</span>
          </div>
        </div>
      </div>
    </div>
  </chart-widget-wrapper>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

//models
import type { IWidgetWrapperOptions, ITooltipPoint, TMeasurementsTimeAndValue } from "@/models/chart.model";
import {
  MetricsType,
  type MetricsResponse,
  type MeasurementResponseValuesInner,
} from "@/swagger-models/cluster-service-client";

//cmps
import { Chart } from "highcharts-vue";
import { ChartWidgetWrapper } from "@/components/dashboard-v2/widgets/common/widget-wrapper/chart-widget-wrapper";

//Highcharts
import type { Options as HighchartsOptions, XAxisOptions, AxisSetExtremesEventObject } from "highcharts";
import dataModule from "highcharts/modules/data";
import Highcharts from "highcharts";

// services
import { clusterService } from "@/services/control-plane/cluster.service/cluster.service";

//utils
import { chartUtil } from "@/utils/chart.util";
import { metricUtil } from "@/utils/metric.util";
import { dateUtil, type IRangeDates } from "@/utils/date.util";
import { roundToDecimal } from "@/utils/format.util";
import { dashboardUtil } from "@/utils/dashboard.util";
import { intervalUtil } from "@/utils/interval.util";
import { EIntervalLabels } from "@/models/interval.model";

export default defineComponent({
  name: "resource-utilization-time-range-widget",
  components: { ChartWidgetWrapper, Highcharts: Chart },
  props: {
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
    nodePoolName: {
      type: String as PropType<string>,
      required: false,
    },
    filterByDates: {
      type: Object as PropType<IRangeDates>,
      required: true,
    },
  },
  data() {
    return {
      wrapperOptions: {
        title: "Resources utilization",
        timeFrame: "",
        tooltipText: "GPU and CPU utilization over time.<br/> Click and drag to view a shorter timeframe.",
      } as IWidgetWrapperOptions,
      chartOptions: null as null | HighchartsOptions,
      averageMetrics: [] as Array<{ name: string; value: string }>,
      measurementMap: {} as Record<string, Array<MeasurementResponseValuesInner>>,
      error: false as boolean,
      loading: false as boolean,
      timeframes: [] as Array<string>,
    };
  },
  async created() {
    dataModule(Highcharts);
    this.loadChartOptions();
    this.loadChartData();
    this.startRefreshLoadData();
  },
  methods: {
    startRefreshLoadData(): void {
      intervalUtil.startInterval(EIntervalLabels.ResourceUtilizationTimeRangeWidget, this.loadChartData);
    },
    stopRefreshLoadData(): void {
      intervalUtil.stopInterval(EIntervalLabels.ResourceUtilizationTimeRangeWidget);
    },
    loadChartOptions(): void {
      this.chartOptions = chartUtil.getBasicWidgetChartOptions({
        yAxisTitle: "",
        type: "line",
        height: 250,
        showSharedCrosshair: true,
        sharedTooltip: true,
        yAxisFormatFunction: (value: number | string): string => `${value}%`,
      });

      this.chartOptions.yAxis = {
        ...this.chartOptions.yAxis,
        tickPositions: [0, 25, 50, 75, 100],
        labels: {
          formatter: function () {
            return this.value + "%";
          },
        },
      };

      this.addTooltipOptions();
      this.addXAxisOptions();
      this.addChartOptions();
    },
    addTooltipOptions(): void {
      if (!this.chartOptions) return;

      this.chartOptions.tooltip = {
        ...this.chartOptions.tooltip,
        useHTML: true,
        formatter: function () {
          if (!this.points) return "";
          const points: Array<ITooltipPoint> = this.points.map((point: Highcharts.TooltipFormatterContextObject) => {
            return {
              name: point.series.name,
              y: point.y ? roundToDecimal(point.y) : point.y,
              color: point.color?.toString(),
              symbol: "%",
            };
          });

          return chartUtil.formatSharedTooltip(points, this.x);
        },
      };
    },
    addXAxisOptions(): void {
      if (!this.chartOptions) return;

      this.chartOptions.xAxis = chartUtil.getDatetimeOptions(this.chartOptions.xAxis as XAxisOptions);
      this.chartOptions.xAxis = {
        ...this.chartOptions.xAxis,
        events: {
          afterSetExtremes: (event: AxisSetExtremesEventObject) => {
            // @ts-ignore
            const { min, max }: { min: number; max: number } = event.target;
            this.setAverageMetrics(min, max);
          },
        },
      };
    },
    addChartOptions(): void {
      if (!this.chartOptions) return;

      this.chartOptions.chart = {
        ...this.chartOptions.chart,
        zoomType: "x",
      };
    },
    async loadChartData(): Promise<void> {
      if (this.chartOptions === null) return;

      try {
        this.wrapperOptions.timeFrame = dateUtil.getTimeframePreview(this.filterByDates);
        this.loading = true;
        this.timeframes = chartUtil.getTimeFrames(this.filterByDates.dateStart, this.filterByDates.dateEnd);

        const metricsResponse: MetricsResponse = await this.getMetric();
        this.measurementMap = metricUtil.mapMeasurementsToMap(metricsResponse);
        this.error = false;
        this.setSeries();
        this.setAverageMetrics();
      } catch (error: unknown) {
        this.error = true;
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async exportCsv(): Promise<void> {
      const metricType: Array<MetricsType> = [
        MetricsType.GpuUtilization,
        MetricsType.GpuMemoryUtilization,
        MetricsType.CpuUtilization,
        MetricsType.CpuMemoryUtilization,
      ];

      try {
        const samples = this.timeframes.length;

        await clusterService.getClusterMetricsCsv(
          this.clusterId,
          dateUtil.convertDateToISO(this.filterByDates.dateStart),
          dateUtil.convertDateToISO(this.filterByDates.dateEnd),
          metricType,
          samples,
        );
      } catch (error: unknown) {
        this.$q.notify(dashboardUtil.getCsvErrorMessage());
        console.error(error);
      }
    },
    setSeries(): void {
      if (this.chartOptions === null) return;

      const gpuUtilizationData: Array<TMeasurementsTimeAndValue> = this.getMeasurements(
        this.timeframes,
        this.measurementMap[MetricsType.GpuUtilization],
      );
      const gpuMemoryUtilizationData: Array<TMeasurementsTimeAndValue> = this.getMeasurements(
        this.timeframes,
        this.measurementMap[MetricsType.GpuMemoryUtilization],
      );
      const cpuUtilizationData: Array<TMeasurementsTimeAndValue> = this.getMeasurements(
        this.timeframes,
        this.measurementMap[MetricsType.CpuUtilization],
      );
      const cpuMemoryUtilizationData: Array<TMeasurementsTimeAndValue> = this.getMeasurements(
        this.timeframes,
        this.measurementMap[MetricsType.CpuMemoryUtilization],
      );

      this.chartOptions.series = [
        {
          name: "GPU compute utilization",
          type: "line",
          data: gpuUtilizationData,
          marker: {
            enabled: false,
            fillColor: "white",
          },
        },
        {
          name: "GPU memory utilization",
          type: "line",
          data: gpuMemoryUtilizationData,
          marker: {
            enabled: false,
            symbol: "circle",
            fillColor: "white",
          },
        },
        {
          name: "CPU compute utilization",
          type: "line",
          data: cpuUtilizationData,
          marker: {
            enabled: false,
            fillColor: "white",
          },
        },
        {
          name: "CPU memory utilization",
          type: "line",
          data: cpuMemoryUtilizationData,
          marker: {
            enabled: false,
            symbol: "circle",
            fillColor: "white",
          },
        },
      ];
    },
    getMeasurements(
      timeframes: string[],
      measurements: Array<MeasurementResponseValuesInner>,
    ): Array<TMeasurementsTimeAndValue> {
      return chartUtil.arrangeMeasurementsByTime(timeframes, measurements);
    },
    setAverageMetrics(min?: number, max?: number): void {
      this.averageMetrics = [
        {
          name: "GPU memory",
          value: this.getAverageMetric(MetricsType.GpuMemoryUtilization, min, max),
        },
        {
          name: "GPU compute",
          value: this.getAverageMetric(MetricsType.GpuUtilization, min, max),
        },
        {
          name: "CPU compute",
          value: this.getAverageMetric(MetricsType.CpuUtilization, min, max),
        },
        {
          name: "CPU memory",
          value: this.getAverageMetric(MetricsType.CpuMemoryUtilization, min, max),
        },
      ];
    },
    calculateAverage(numbers: number[]): number {
      if (numbers.length === 0) return 0;

      const sum = numbers.reduce((acc: number, num: number) => acc + num, 0);
      return sum / numbers.length;
    },
    formatPercentage(num: number): string {
      return `${num.toFixed(2)}%`;
    },
    getAverageMetric(type: MetricsType, min?: number, max?: number): string {
      let measurements: Array<MeasurementResponseValuesInner> = this.measurementMap[type]
        ? this.measurementMap[type]
        : [];

      if (min !== undefined && max !== undefined) {
        measurements = measurements.filter((measurement: MeasurementResponseValuesInner) => {
          if (!measurement.timestamp) return false;

          const currentTimestamp = new Date(measurement.timestamp).getTime();
          return currentTimestamp >= min && currentTimestamp <= max;
        });
      }

      const measurementsValue: number[] = measurements.map((measurement: MeasurementResponseValuesInner) =>
        Number(measurement.value),
      );

      return this.formatPercentage(this.calculateAverage(measurementsValue));
    },
    async getMetric(): Promise<MetricsResponse> {
      const metricType: Array<MetricsType> = [
        MetricsType.GpuUtilization,
        MetricsType.GpuMemoryUtilization,
        MetricsType.CpuUtilization,
        MetricsType.CpuMemoryUtilization,
      ];
      const samples = this.timeframes.length;
      if (this.nodePoolName) {
        return await clusterService.getNodepoolMetrics(
          this.clusterId,
          this.nodePoolName,
          dateUtil.convertDateToISO(this.filterByDates.dateStart),
          dateUtil.convertDateToISO(this.filterByDates.dateEnd),
          metricType,
          samples,
        );
      } else {
        return await clusterService.getClusterMetrics(
          this.clusterId,
          dateUtil.convertDateToISO(this.filterByDates.dateStart),
          dateUtil.convertDateToISO(this.filterByDates.dateEnd),
          metricType,
          samples,
        );
      }
    },
  },
  watch: {
    filterByDates(): void {
      this.loadChartData();
    },
    nodePoolName: {
      async handler(): Promise<void> {
        await this.loadChartData();
      },
    },
    clusterId: {
      async handler(): Promise<void> {
        await this.loadChartData();
      },
    },
  },
  unmounted() {
    this.stopRefreshLoadData();
  },
});
</script>

<style scoped lang="scss">
.resource-utilization-time-range-widget-body {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;

  .chart-container {
    height: 100%;
    display: flex;
    align-items: center;
    flex: 1;
  }
  .average-data {
    border-left: 1px solid $black-12;
    padding-left: 16px;
    min-width: 210px;

    .metric-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $black-12;
      padding: 1px 0;

      &:first-child {
        border-top: 1px solid $black-12;
      }

      .metric-value {
        font-size: 30px;
        font-weight: 500;
      }
    }
  }
}
</style>
