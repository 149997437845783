import type { IDepartmentTable } from "@/models/department.model";
import { EResourceState, EResourceType } from "@/models/resource.model";
import {
  ECustomCell,
  ECustomHeader,
  type ICustomHeaderRadioButtonsConfig,
  type ITableColumn,
} from "@/models/table.model";
import { dateUtil } from "@/utils/date.util";
import { convertToBytes, memoryFormat, numberFormat, toPercent } from "@/utils/format.util";
import { resourceUtil } from "@/utils/resource.util";
import { EDepartmentColumnName } from "@/models/department.model";
import { CLUSTER_COLUMN_FILTER_NAME, EColumnFilterType } from "@/models/filter.model";
import {
  DepartmentFilterSortFields,
  type DepartmentProjectChild,
  type Resources,
} from "@/swagger-models/org-unit-service-client";
import { orgUnitUtil } from "@/utils/org-unit.util";
import { EOrgUnitOverTimeColumnName, ORG_UNIT_OVERTIME_CUSTOM_HEADER_CONFIG } from "@/models/org-unit.model";

export const allDepartmentColumnsMap: Record<string, ITableColumn> = {
  departmentName: {
    name: DepartmentFilterSortFields.Name,
    label: "Department",
    field: (row: IDepartmentTable) => row.name,
    sortable: true,
    align: "left",
    filter: {
      type: EColumnFilterType.FreeText,
    },
  },
  description: {
    name: "description",
    label: "Description",
    field: (row: IDepartmentTable) => row.description,
    format: (val: string) => val || "-",
    sortable: false,
    align: "left",
    customCell: ECustomCell.DESCRIPTION_COL,
    hideFilter: true,
  },
  cluster: {
    name: CLUSTER_COLUMN_FILTER_NAME,
    label: "Cluster",
    field: (row: IDepartmentTable) => row.clusterId,
    sortable: true,
    align: "left",
    customCell: ECustomCell.CLUSTER_ID_TO_NAME_COL,
    hideFilter: true,
  },
  projects: {
    name: EDepartmentColumnName.Projects,
    label: "Project(s)",
    field: (row: IDepartmentTable) => row.children,
    sortable: false,
    align: "left",
    format: (projects: Array<DepartmentProjectChild>): Array<string> | string => {
      if (!projects) return "-";
      return projects.map((p: DepartmentProjectChild) => p.name);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "projects-clicked" },
    hideFilter: true,
  },
  nodePools: {
    name: EDepartmentColumnName.NodePools,
    label: "Node pool(s)",
    field: (row: IDepartmentTable) => row.resources,
    sortable: false,
    align: "left",
    format: (resources: Resources[]): string[] => resources.map((resources) => resources.nodePool?.name || ""),
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "node-pools-clicked" },
    hideFilter: true,
  },
  accessRules: {
    name: EDepartmentColumnName.AccessRules,
    label: "Subject(s)",
    field: (row: IDepartmentTable) => row.rolesNames,
    sortable: false,
    align: "left",
    customCell: ECustomCell.ROLE_ASSIGMENT_COL,
    customCellEvent: { emitName: "access-rules-clicked" },
    hideFilter: true,
  },
  allocatedGpus: {
    name: DepartmentFilterSortFields.GpuAllocated,
    label: "Allocated GPUs",
    field: (row: IDepartmentTable) => row.status?.quotaStatus?.allocated?.gpu,
    sortable: true,
    align: "left",
    format: (allocatedGpus: number | undefined) =>
      allocatedGpus ? resourceUtil.getResourceDisplayValue(allocatedGpus, EResourceType.GPU) : "-",
    filter: {
      type: EColumnFilterType.Numeric,
    },
  },
  averageGpuAllocation: {
    name: EOrgUnitOverTimeColumnName.AverageGpuAllocation,
    label: "Avg. GPU allocation",
    field: (row: IDepartmentTable) => row.averageGpuAllocation,
    sortable: true,
    align: "left",
    format: (averageGpuAllocation: number | null) => (averageGpuAllocation ? numberFormat(averageGpuAllocation) : "-"),
    customHeader: ECustomHeader.RadioButtonsTableHeader,
    customHeaderEventName: "avg-gpu-allocation-timeframe-changed",
    customHeaderConfig: {
      ...ORG_UNIT_OVERTIME_CUSTOM_HEADER_CONFIG,
      selectedValueFilterByKey: "avgGpuAllocationTimeframe",
    } as ICustomHeaderRadioButtonsConfig,
    filter: {
      type: EColumnFilterType.Numeric,
    },
  },
  averageGpuUtilization: {
    name: EOrgUnitOverTimeColumnName.AverageGpuUtilization,
    label: "Avg. GPU utilization",
    field: (row: IDepartmentTable) => row.averageGpuUtilization,
    sortable: true,
    align: "left",
    format: (averageGpuUtilization: number | null) =>
      averageGpuUtilization ? numberFormat(averageGpuUtilization) + "%" : "-",
    customHeader: ECustomHeader.RadioButtonsTableHeader,
    customHeaderEventName: "avg-gpu-utilization-timeframe-changed",
    customHeaderConfig: {
      ...ORG_UNIT_OVERTIME_CUSTOM_HEADER_CONFIG,
      selectedValueFilterByKey: "avgGpuUtilizationTimeframe",
    } as ICustomHeaderRadioButtonsConfig,
    filter: {
      type: EColumnFilterType.Numeric,
    },
  },
  allocatedCpu: {
    name: EDepartmentColumnName.AllocatedCpu,
    label: "Allocated CPUs (Cores)",
    field: (row: IDepartmentTable) => row.status?.quotaStatus?.allocated?.cpu,
    sortable: false,
    align: "left",
    format: (allocatedCpu: number | undefined) =>
      allocatedCpu ? resourceUtil.getResourceDisplayValue(allocatedCpu, EResourceType.CPU) : "-",
    hideFilter: true,
  },
  allocatedMemory: {
    name: EDepartmentColumnName.AllocatedMemory,
    label: "Allocated CPU memory",
    field: (row: IDepartmentTable) => row.status?.quotaStatus?.allocated?.memory,
    sortable: false,
    align: "left",
    format: (allocatedMemory: number | undefined) =>
      allocatedMemory ? memoryFormat(convertToBytes(allocatedMemory, "MB")) : "-",
    hideFilter: true,
  },
  gpuUtilization: {
    name: EDepartmentColumnName.GpuUtilization,
    label: "GPU allocation ratio",
    field: (row: IDepartmentTable) => row.status?.quotaStatus?.allocated?.gpu,
    sortable: false,
    align: "left",
    format: (allocatedGpus: number | undefined, department: IDepartmentTable) =>
      orgUnitUtil.getAllocationRatioDisplayValue(allocatedGpus || 0, department?.totalResources),
    hideFilter: true,
  },
  cpuUtilization: {
    name: EDepartmentColumnName.CpuUtilization,
    label: "CPU allocation ratio",
    field: (row: IDepartmentTable) => row.status?.quotaStatus?.allocated?.cpu,
    sortable: true,
    align: "left",
    format: (allocatedCpu: number | undefined, department: IDepartmentTable) =>
      toPercent(allocatedCpu || 0, department.totalResources?.cpuQuota || 0),
    hideFilter: true,
  },
  memoryUtilization: {
    name: EDepartmentColumnName.MemoryUtilization,
    label: "CPU memory allocation ratio",
    field: (row: IDepartmentTable) => row.status?.quotaStatus?.allocated?.memory,
    sortable: false,
    align: "left",
    format: (allocatedMemory: number | undefined, department: IDepartmentTable) =>
      toPercent(resourceUtil.fromBytesToMiB(allocatedMemory || 0), department.totalResources?.memoryQuota || 0),
    hideFilter: true,
  },
  gpuQuota: {
    name: EDepartmentColumnName.GpuQuota,
    label: "GPU quota",
    field: (row: IDepartmentTable): IDepartmentTable => row,
    sortable: false,
    align: "left",
    customCell: ECustomCell.DEPARTMENT_QUOTA_COL,
    hideFilter: true,
    exportFormat: (row: IDepartmentTable) => row.totalResources?.gpuQuota,
  },
  cpuQuota: {
    name: EDepartmentColumnName.CpuQuota,
    label: "CPU quota (cores)",
    field: (row: IDepartmentTable) => row.totalResources?.cpuQuota,
    sortable: false,
    align: "left",
    format: (cpuQuota: number | null | undefined) => resourceUtil.getResourceDisplayValue(cpuQuota, EResourceType.CPU),
    hideFilter: true,
    exportFormat: (row: IDepartmentTable) => row.totalResources?.cpuQuota,
  },
  memoryQuota: {
    name: EDepartmentColumnName.MemoryQuota,
    label: "CPU memory quota",
    field: (row: IDepartmentTable) => row.totalResources?.memoryQuota,
    sortable: false,
    align: "left",
    format: (memoryQuota: number | null | undefined) => {
      if (memoryQuota === null || memoryQuota === undefined) return EResourceState.Unlimited;
      const memoryQuotaInMib = resourceUtil.fromMbToMib(memoryQuota);
      return resourceUtil.getResourceDisplayValue(memoryQuotaInMib, EResourceType.MEMORY);
    },
    hideFilter: true,
    exportFormat: (row: IDepartmentTable) => row.totalResources?.memoryQuota,
  },
  createdAt: {
    name: DepartmentFilterSortFields.CreatedAt,
    label: "Creation time",
    field: (row: IDepartmentTable) => row.createdAt,
    sortable: true,
    align: "left",
    format: (createdAt: string) => (createdAt ? dateUtil.dateAndTimeFormat(new Date(createdAt)) : "-"),
    filter: {
      type: EColumnFilterType.Date,
    },
  },
  workloads: {
    name: EDepartmentColumnName.Workloads,
    label: "Workload(s)",
    field: () => "View",
    align: "left",
    sortable: false,
    hideFilter: true,
    customCell: ECustomCell.LINK_COL,
    customCellEvent: { emitName: "workloads-clicked" },
  },
};

export const departmentIndexColumns: Array<ITableColumn> = [
  { ...allDepartmentColumnsMap.departmentName, display: true, mandatory: true },
  { ...allDepartmentColumnsMap.description, display: false },
  { ...allDepartmentColumnsMap.cluster, display: true },
  { ...allDepartmentColumnsMap.projects, display: true },
  { ...allDepartmentColumnsMap.nodePools, display: true },
  { ...allDepartmentColumnsMap.accessRules, display: true },
  { ...allDepartmentColumnsMap.allocatedGpus, display: true },
  { ...allDepartmentColumnsMap.averageGpuAllocation, display: false },
  { ...allDepartmentColumnsMap.averageGpuUtilization, display: false },
  { ...allDepartmentColumnsMap.allocatedCpu, display: false },
  { ...allDepartmentColumnsMap.allocatedMemory, display: false },
  { ...allDepartmentColumnsMap.gpuUtilization, display: true },
  { ...allDepartmentColumnsMap.cpuUtilization, display: false },
  { ...allDepartmentColumnsMap.memoryUtilization, display: false },
  { ...allDepartmentColumnsMap.gpuQuota, display: true },
  { ...allDepartmentColumnsMap.cpuQuota, display: false },
  { ...allDepartmentColumnsMap.memoryQuota, display: false },
  { ...allDepartmentColumnsMap.createdAt, display: true },
  { ...allDepartmentColumnsMap.workloads, display: true },
];

export const departmentDependentColumns = {
  cpu: [
    allDepartmentColumnsMap.cpuQuota.name,
    allDepartmentColumnsMap.memoryQuota.name,
    allDepartmentColumnsMap.memoryUtilization.name,
    allDepartmentColumnsMap.cpuUtilization.name,
    allDepartmentColumnsMap.allocatedCpu.name,
    allDepartmentColumnsMap.allocatedMemory.name,
  ],
  nodePools: [allDepartmentColumnsMap.nodePools.name],
  accessRules: [allDepartmentColumnsMap.accessRules.name],
  overTimeData: [allDepartmentColumnsMap.averageGpuAllocation.name, allDepartmentColumnsMap.averageGpuUtilization.name],
};
