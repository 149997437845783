<template>
  <stat-widget-wrapper :options="options" @link-clicked="redirectToNodes" :loading="displayLoading" :error="error">
    <template #data> {{ idleAllocatedGpusFormatted }}</template>
  </stat-widget-wrapper>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { StatWidgetWrapper } from "@/components/dashboard-v2/widgets/common/widget-wrapper/stat-widget-wrapper";
import { EWidgetEntity, type IWidgetWrapperOptions } from "@/models/chart.model";
import { clusterService } from "@/services/control-plane/cluster.service/cluster.service";
import { NodeTelemetryType } from "@/swagger-models/cluster-service-client";
import { widgetUtil } from "@/utils/widget.util";
import { HttpErrorResponse } from "@/models/http-response.model";
import { NODE_ROUTE_NAMES } from "@/router/node.routes/node.routes.names";
import type { IFilterBy } from "@/models/filter.model";
import { filterService } from "@/services/filter.service/filter.service";
import { allNodesColumns, ENodeColumnName } from "@/table-models/node.table-model";
import { ETableFilters } from "@/models/table.model";
import { useClusterStore } from "@/stores/cluster.store";
import { formatUtil } from "@/utils/format.util/format.util";
import { intervalUtil } from "@/utils/interval.util";
import { EIntervalLabels } from "@/models/interval.model";
export default defineComponent({
  name: "idle-allocated-gpu-widget",
  components: { StatWidgetWrapper },
  props: {
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
    nodePoolName: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    return {
      options: {
        timeFrame: "Now",
        linkText: "All nodes",
        title: "Idle allocated GPU devices",
        entityType: EWidgetEntity.Node,
        tooltipText: "Allocated GPU devices that are idle for more than 5 minutes",
      } as IWidgetWrapperOptions,
      idleAllocatedGpus: 0 as number,
      error: false as boolean,
      displayLoading: true as boolean,
      clusterStore: useClusterStore(),
    };
  },
  created() {
    this.getIdleAllocatedGpus();
    this.startRefreshLoadData();
  },
  computed: {
    idleAllocatedGpusFormatted(): string {
      return formatUtil.numberFormat(this.idleAllocatedGpus, true);
    },
  },
  methods: {
    startRefreshLoadData(): void {
      intervalUtil.startInterval(EIntervalLabels.IdleAllocatedGpuWidget, this.getIdleAllocatedGpus);
    },
    stopRefreshLoadData(): void {
      intervalUtil.stopInterval(EIntervalLabels.IdleAllocatedGpuWidget);
    },
    async getIdleAllocatedGpus(): Promise<void> {
      try {
        const response = await clusterService.getNodeTelemetry(
          NodeTelemetryType.IdleAllocatedGpus,
          this.clusterId,
          this.nodePoolName,
          [],
        );
        const values = widgetUtil.sumAllTelemetryValues(response.values);
        this.idleAllocatedGpus = values;
      } catch (error: unknown) {
        this.handleError(error);
      } finally {
        this.displayLoading = false;
      }
    },
    handleError(error: unknown): void {
      this.error = true;
      if (error instanceof HttpErrorResponse) {
        console.error(error.message);
      } else {
        console.error("Error fetching data", error);
      }
    },
    redirectToNodes(): void {
      this.setColumnFilter();
      this.$router.push({ name: NODE_ROUTE_NAMES.NODE_INDEX });
    },
    getNodesFilterBy(): IFilterBy {
      const defaultFilters: IFilterBy = filterService.getDefaultFilters(ENodeColumnName.Status, allNodesColumns);
      return filterService.loadFilters(window.location, ETableFilters.NODE, defaultFilters);
    },
    setColumnFilter(): void {
      const nodesFilterBy: IFilterBy = this.getNodesFilterBy();

      filterService.setColumnFilter(nodesFilterBy, "Ready", ENodeColumnName.Status, ETableFilters.NODE);

      if (this.nodePoolName) {
        this.setNodePoolColumnFilter(nodesFilterBy);
      } else {
        this.removeNodePoolFilter(nodesFilterBy);
      }
    },
    setNodePoolColumnFilter(nodesFilterBy: IFilterBy): void {
      filterService.setColumnFilter(
        nodesFilterBy,
        this.nodePoolName as string,
        ENodeColumnName.NodePool,
        ETableFilters.NODE,
      );
    },
    removeNodePoolFilter(nodesFilterBy: IFilterBy): void {
      filterService.removeColumnFilter(nodesFilterBy, ENodeColumnName.NodePool, ETableFilters.NODE);
    },
  },
  watch: {
    nodePoolName: {
      handler(): void {
        this.displayLoading = true;
        this.getIdleAllocatedGpus();
      },
    },
    clusterId: {
      handler(): void {
        this.displayLoading = true;
        this.getIdleAllocatedGpus();
      },
    },
  },
  unmounted() {
    this.stopRefreshLoadData();
  },
});
</script>

<style scoped lang="scss"></style>
