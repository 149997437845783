<template>
  <section class="workspace-new column items-center q-pt-md">
    <runai-form-wrapper :form-state="workspace">
      <q-form ref="workspaceForm">
        <section class="create-workspace-form">
          <runai-expansion-wrapper>
            <cluster-selection-section
              entity-name="workspace"
              :cluster-id="selectedClusterId"
              @update:cluster-id="updateClusterId"
              cluster-connectivity-required
            />

            <transition name="fade">
              <project-section
                v-if="selectedClusterId && isClusterSectionValid"
                aid="project-section"
                :loading="loadingProject"
                :selected-project-id="selectedProjectId"
                :projects="projects"
                :cluster-id="selectedClusterId"
                @project-changed="onSelectedProject"
              />
            </transition>

            <transition-group name="fade">
              <template v-if="selectedProjectId && selectedProjectId !== -1">
                <template-section
                  aid="template-section"
                  :templates="templates"
                  :loading="loadingTemplate"
                  @template-changed="onSelectedTemplate"
                  :template-id="selectedTemplateId || null"
                />
                <workload-name-section
                  entity-type="workspace"
                  aid="ws-name-section"
                  v-model:name="workspaceName"
                  :disable-closing="true"
                  :project-id="selectedProjectId"
                  :cluster-id="selectedClusterId"
                />
              </template>
            </transition-group>
          </runai-expansion-wrapper>
          <section class="row items-center q-mt-md">
            <q-field
              class="col-4 form-hint no-padding"
              :model-value="displayFormHint"
              :rules="[isFormIncomplete]"
            ></q-field>
            <div class="buttons q-ml-auto">
              <q-btn flat class="q-mr-sm" color="primary" label="Cancel" aid="cancel-btn" @click="onCancel" />
              <q-btn
                label="continue"
                aid="create-workspace-continue-btn"
                @click="continueToEdit"
                :loading="submitting"
                color="primary"
              ></q-btn>
            </div>
          </section>
        </section>
      </q-form>
    </runai-form-wrapper>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// store
import { useWorkspaceStore } from "@/stores/workspace.store";
import { useProjectStore } from "@/stores/project.store";
import { useClusterStore } from "@/stores/cluster.store";
import { useWorkloadTemplateStore } from "@/stores/workload-template.store";
import { useAppStore } from "@/stores/app.store";

// services
import { alertUtil } from "@/utils/alert.util";
import { requestToLeave } from "@/services/infra/router.service/router.service";
import { dataSourceService } from "@/services/control-plane/data-source.service/data-source.service";
import { urlService } from "@/services/url.service/url.service";

// cmps
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";
import { RunaiExpansionWrapper } from "@/components/common/runai-expansion-wrapper";
import { WorkloadNameSection } from "@/components/section/workload-name-section";
import { ProjectSection } from "@/components/section/project-section";
import { TemplateSection } from "@/components/section/template-section";
import { ClusterSelectionSection } from "@/components/cluster/cluster-selection/cluster-selection-section";

// models
import type { IProject } from "@/models/project.model";
import { type WorkloadTemplate, type PVCAsset, UidGidSource } from "@/swagger-models/assets-service-client";
import type { IUIWorkloadCreation } from "@/models/workload.model";
import type { ProjectMetadata } from "@/swagger-models/org-unit-service-client";

// route
import { WORKSPACE_ROUTE_NAMES } from "@/router/workspace.routes/workspace.routes.names";

// utils
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { dataSourceUtil } from "@/utils/data-source.util";
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes/workloads.routes.names";
import { useEnvironmentStore } from "@/stores/environment.store";
import { useAuthStore } from "@/stores/auth.store";
import { workspaceService } from "@/services/control-plane/workspace.service/workspace.service";
import { deepCopy } from "@/utils/common.util";

export default defineComponent({
  components: {
    RunaiExpansionWrapper,
    RunaiFormWrapper,
    ProjectSection,
    WorkloadNameSection,
    TemplateSection,
    ClusterSelectionSection,
  },
  data() {
    return {
      workspaceStore: useWorkspaceStore(),
      workloadTemplateStore: useWorkloadTemplateStore(),
      projectStore: useProjectStore(),
      appStore: useAppStore(),
      clusterStore: useClusterStore(),
      environmentStore: useEnvironmentStore(),
      authStore: useAuthStore(),

      workspaceForm: null as HTMLFormElement | null,

      loadingProject: false as boolean,
      loadingTemplate: false as boolean,
      submitting: false as boolean,
      workspace: workloadUtil.getEmptyUIWorkloadCreation() as IUIWorkloadCreation,

      displayFormHint: false as boolean,
      timeOutId: null as ReturnType<typeof setTimeout> | null,
      isClusterSectionValid: false as boolean,

      selectedProjectId: null as number | null,
      selectedTemplateId: null as string | null,
      selectedClusterId: "" as string,
      workspaceName: "" as string,
    };
  },
  async created() {
    this.appStore.setPageLoading(false);
  },
  async mounted() {
    this.workspaceForm = this.$refs["workspaceForm"] as HTMLFormElement;
    const createdEntityId: string | undefined = this.$route.query.createdEntityId?.toString();
    const savedProjectId = this.workspaceStore.workspace.projectId || -1;

    const selectedDefaultClusterId = this.getDefaultClusterId();
    if (selectedDefaultClusterId) {
      await this.updateClusterId(selectedDefaultClusterId);
    }

    const selectedDefaultProjectId = this.getDefaultProjectId(savedProjectId, createdEntityId);
    if (selectedDefaultProjectId) {
      await this.onSelectedProject(selectedDefaultProjectId);
    }
  },
  computed: {
    projects(): Array<ProjectMetadata> {
      return this.projectStore.projectResourceList;
    },
    templates(): Array<WorkloadTemplate> {
      return this.workloadTemplateStore.workloadTemplateList;
    },
  },
  methods: {
    getDefaultClusterId(): string {
      let selectedDefaultClusterId = "";
      if (this.clusterStore.clusterList.length === 1) {
        selectedDefaultClusterId = this.clusterStore.clusterList[0].uuid;
      } else if (this.$route.query.clusterId) {
        selectedDefaultClusterId = this.$route.query.clusterId.toString();
      } else if (this.workspaceStore.workspace.clusterId) {
        selectedDefaultClusterId = this.workspaceStore.workspace.clusterId;
      }

      return selectedDefaultClusterId;
    },
    getDefaultProjectId(savedProjectId: number, createdEntityId?: string): number | null {
      let selectedDefaultProjectId = null;
      if (createdEntityId && this.projects.length > 1) {
        selectedDefaultProjectId = Number(createdEntityId);
      } else if (savedProjectId !== -1) {
        selectedDefaultProjectId = savedProjectId;
      }

      return selectedDefaultProjectId;
    },
    async loadProjects(): Promise<void> {
      try {
        this.loadingProject = true;
        this.selectedTemplateId = null;
        await Promise.all([
          this.projectStore.loadProjectsMetaData(this.selectedClusterId),
          this.projectStore.loadProjects({
            withAccessRules: false,
            withNamespace: true,
            clusterId: this.selectedClusterId,
          }),
        ]);

        if (this.projects.length === 1) {
          await this.onSelectedProject(+this.projects[0].id);
        } else if (this.workspaceStore.workspace.projectId !== -1) {
          await this.onSelectedProject(this.workspaceStore.workspace.projectId);
        }
      } catch (error: unknown) {
        this.$q.notify(alertUtil.getError("Failed to load projects"));
        console.error(error);
        this.appStore.setFallback(true);
      } finally {
        this.loadingProject = false;
      }
    },
    async onSelectedProject(projectId: number | null): Promise<void> {
      try {
        this.selectedProjectId = projectId || -1;
        this.selectedTemplateId = null;
        if (!projectId) return;

        await this.loadTemplates();
      } catch (error: unknown) {
        console.error(error);
      }
    },
    async loadWorkspaceModel(projectId: string, templateId?: string | null): Promise<void> {
      const tempId = templateId === "-1" ? undefined : templateId;
      const workspaceModel = await workspaceService.getWorkspaceMergedWithPolicy(
        projectId,
        undefined,
        tempId || undefined,
      );

      this.workspaceStore.setLoadedWorkspaceModelWithDefaults(deepCopy(workspaceModel));
      this.workspace = workloadUtil.convertWorkloadToWorkloadUI({
        ...workspaceModel,
        meta: {
          ...workspaceModel.meta,
          name: this.workspaceName,
          clusterId: this.selectedClusterId,
          projectId: this.selectedProjectId?.toString() || "",
        },
      });

      this.workspaceStore.setWorkspace(this.workspace);
    },

    async loadTemplates(): Promise<void> {
      this.loadingTemplate = true;
      await this.workloadTemplateStore.loadWorkloadTemplates({ projectId: this.selectedProjectId, isWorkspace: true });
      if (this.templates.length === 0) {
        this.selectedTemplateId = "-1";
      } else if (
        this.workspaceStore.templateId &&
        (this.workspaceStore.templateId === "-1" ||
          this.templates.find((template) => template.meta.id === this.workspaceStore.templateId))
      ) {
        this.selectedTemplateId = this.workspaceStore.templateId;
      }

      this.loadingTemplate = false;
    },
    onSelectedTemplate(templateId: string | null): void {
      this.selectedTemplateId = templateId;
      this.workspaceStore.setTemplate(templateId);
    },
    redirectToPrevRoute(): void {
      this.$router.push({ name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX });
    },
    async onCancel(): Promise<void> {
      const allowToLeave: boolean = await requestToLeave();
      if (allowToLeave) {
        this.redirectToPrevRoute();
      }
    },
    async continueToEdit(): Promise<void> {
      this.displayFormHint = false;
      this.submitting = true;
      const success = await this.validate();
      if (!success) {
        this.submitting = false;
        this.showHint();
        return;
      }

      if (!this.selectedProjectId) {
        console.error("ProjectId is missing");
        this.$q.notify(alertUtil.getError("Failed to create workspace"));
        this.submitting = false;
        return;
      }

      await this.loadWorkspaceModel(this.selectedProjectId.toString(), this.selectedTemplateId);

      this.workspace.specificEnv = {
        ...this.workspace.specificEnv,
        nodePools: this.getNodePoolsFromProject(this.workspace.projectId),
        backoffLimit: this.workspace.specificEnv.backoffLimit,
      };

      const template: WorkloadTemplate | undefined = this.templates.find(
        (template) => template.meta.id === this.selectedTemplateId,
      );
      if (template?.spec.assets.workloadVolumes?.length) {
        const pvcs: Array<PVCAsset> = await dataSourceService.loadPVCAssets(template.spec.assets.workloadVolumes);
        this.workspace.assets.uiVolumes = dataSourceUtil.mapPvcsToUiVolumes(pvcs);
      }

      await this.setSpecificUserGidUidIfNeeded();

      this.workspaceStore.setWorkspace(this.workspace);
      await this.$router.push({ name: WORKSPACE_ROUTE_NAMES.WORKSPACE_ASSETS_EDIT });
    },
    validate(): Promise<boolean> {
      return (this.workspaceForm as HTMLFormElement).validate();
    },
    showHint(): void {
      this.displayFormHint = true;
      this.timeOutId && clearTimeout(this.timeOutId);
      this.timeOutId = setTimeout(() => (this.displayFormHint = false), 15000);
    },
    isFormIncomplete(val: boolean): boolean | string {
      return !val ? true : "Please review and fix the issues in the form";
    },
    findProject(projectId: number): IProject | undefined {
      return this.projectStore.projects.find((project: IProject) => +project.id === projectId);
    },
    getNodePoolsFromProject(projectId: number): Array<string> | null {
      const project: IProject | undefined = this.findProject(projectId);
      if (!project) return null;

      return project.defaultNodePools || null;
    },
    async setSpecificUserGidUidIfNeeded(): Promise<void> {
      if (this.workspace.assets.environment) {
        const relevantEnvironment = await this.environmentStore.loadById(this.workspace.assets.environment);
        if (relevantEnvironment?.spec.uidGidSource === UidGidSource.FromIdpToken) {
          this.workspace.specificEnv.runAsUid = this.authStore.getUID || null;
          this.workspace.specificEnv.runAsGid = this.authStore.getGID || null;
          this.workspace.specificEnv.supplementalGroups = this.authStore.getSupplementaryGroups;
        }
      }
    },
    async updateClusterId(clusterId: string): Promise<void> {
      this.isClusterSectionValid = false;
      this.displayFormHint = false;
      this.selectedClusterId = clusterId;
      this.selectedProjectId = -1;
      urlService.updateQueryParams({ clusterId, createdEntityId: null });
      this.$nextTick(async () => {
        this.isClusterSectionValid = await this.validate();
        if (this.isClusterSectionValid) {
          await this.loadProjects();
        }
      });
    },
  },
  unmounted() {
    this.timeOutId && clearTimeout(this.timeOutId);
  },
});
</script>
