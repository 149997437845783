<template>
  <div
    class="information-bar flex-1 row gap-10 items-center"
    :class="{ 'bar-bg-info': type === 'info', 'bar-bg-warning': type === 'warning' }"
  >
    <q-icon class="icon" :class="{ info: type === 'info', warning: type === 'warning' }" :name="icon" size="16px" />
    <span class="information-text flex-1">
      <slot></slot>
    </span>
  </div>
</template>
<script lang="ts">
import { type PropType, defineComponent } from "vue";

export default defineComponent({
  props: {
    type: {
      type: String as PropType<"info" | "warning">,
      default: "info",
    },
  },
  computed: {
    icon(): string {
      switch (this.type) {
        case "warning":
          return "fa-regular fa-triangle-exclamation";
        default:
          return "fa-regular fa-circle-info";
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.bar-bg-info {
  background-color: $light-blue-1;
}

.bar-bg-warning {
  background-color: $yellow-20;
}
.information-bar {
  padding: 8px 16px;
  animation: fade-in 0.3s ease-in-out forwards;

  .icon {
    font-size: 16px;
  }
  .info {
    color: $primary;
  }

  .warning {
    color: $warning;
  }

  .information-text {
    font-size: 13px;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
