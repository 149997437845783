import { ETableFilters, type ITableColumn } from "@/models/table.model";
import type { IPolicy } from "@/stores/policy.store";
import type {
  ComputeAsset,
  CredentialsListResponseEntry,
  DatasourceListResponseEntry,
  EnvironmentAsset,
  WorkloadTemplate,
} from "@/swagger-models/assets-service-client";
import type { IDepartment } from "@/models/department.model";
import { EProjectColumnName, type IProject } from "@/models/project.model";
import { EDepartmentColumnName } from "@/models/department.model";
import { EIntervalLabels } from "@/models/interval.model";
import { ENodeColumnName } from "@/table-models/node.table-model";
import type { INodePool } from "@/models/node-pool.model";
import { ENodePoolColumnName } from "@/table-models/node-pool.table-model";
import { departmentIndexColumns } from "@/table-models/department.table-model";
import { projectIndexColumns } from "@/table-models/project.table-model";
import { auditIndexColumns } from "@/table-models/audit.table-model";
import type { AuditLog } from "@/swagger-models/audit-service-client";
import type { ICluster } from "@/models/cluster.model";

interface IIndexTableInfo {
  storageFilterKey: ETableFilters;
  sortByDefault: string;
  entityText: string;
  intervalLabel?: EIntervalLabels;
  intervalLabelPostCreation?: EIntervalLabels;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getEntityId: (e: any) => string;
}

interface IAdvancedIndexTableInfo extends IIndexTableInfo {
  allEntityColumns: Array<ITableColumn>; // Required to enable local search functionality
}

export enum EIndexPages {
  COMPUTE_RESOURCE = "computeResource",
  DATA_SOURCE = "dataSource",
  ENVIRONMENT = "environment",
  CREDENTIAL = "credential",
  WORKLOAD_TEMPLATE = "workloadTemplate",
  POLICY = "policy",
  NODE = "node",
  NODE_POOL = "node-pool",
  CLUSTER = "cluster",
}

export enum EAdvancedIndexPages {
  DEPARTMENT = "department",
  PROJECT = "project",
  AUDIT = "audit",
}

export const useTableDataIndexMapper: Record<EIndexPages, IIndexTableInfo> = {
  [EIndexPages.COMPUTE_RESOURCE]: {
    storageFilterKey: ETableFilters.COMPUTE_RESOURCE,
    sortByDefault: "compute-resource",
    entityText: "compute resources",
    getEntityId: (e: ComputeAsset) => e.meta?.id || "",
  },
  [EIndexPages.DATA_SOURCE]: {
    storageFilterKey: ETableFilters.DATA_SOURCE,
    sortByDefault: "data-source",
    entityText: "data sources",
    getEntityId: (e: DatasourceListResponseEntry) => e.meta?.id || "",
    intervalLabel: EIntervalLabels.DataSourcesIndex,
    intervalLabelPostCreation: EIntervalLabels.IndexPostCreationRefreshRate,
  },
  [EIndexPages.ENVIRONMENT]: {
    storageFilterKey: ETableFilters.ENVIRONMENT,
    sortByDefault: "environment",
    entityText: "environments",
    getEntityId: (e: EnvironmentAsset) => e.meta?.id || "",
  },
  [EIndexPages.CREDENTIAL]: {
    storageFilterKey: ETableFilters.CREDENTIAL,
    sortByDefault: "credentials",
    entityText: "credentials",
    getEntityId: (e: CredentialsListResponseEntry) => e.meta?.id || "",
    intervalLabel: EIntervalLabels.CredentialsIndex,
    intervalLabelPostCreation: EIntervalLabels.IndexPostCreationRefreshRate,
  },
  [EIndexPages.WORKLOAD_TEMPLATE]: {
    storageFilterKey: ETableFilters.TEMPLATE,
    sortByDefault: "template",
    entityText: "templates",
    getEntityId: (e: WorkloadTemplate) => e.meta?.id || "",
  },
  [EIndexPages.POLICY]: {
    storageFilterKey: ETableFilters.POLICY,
    sortByDefault: "policy",
    entityText: "policies",
    getEntityId: (e: IPolicy) => e.policyId || "",
    intervalLabel: EIntervalLabels.PolicyIndex,
    intervalLabelPostCreation: EIntervalLabels.IndexPostCreationRefreshRate,
  },
  [EIndexPages.NODE]: {
    storageFilterKey: ETableFilters.NODE,
    sortByDefault: ENodeColumnName.Node,
    entityText: "nodes",
    getEntityId: () => "",
    intervalLabel: EIntervalLabels.NodeIndex,
  },
  [EIndexPages.NODE_POOL]: {
    storageFilterKey: ETableFilters.NODE_POOL,
    sortByDefault: ENodePoolColumnName.Name,
    entityText: "node pools",
    getEntityId: (nodePool: INodePool) => nodePool.id.toString() || "",
    intervalLabel: EIntervalLabels.NodePoolIndex,
    intervalLabelPostCreation: EIntervalLabels.IndexPostCreationRefreshRate,
  },
  [EIndexPages.CLUSTER]: {
    storageFilterKey: ETableFilters.CLUSTER,
    sortByDefault: "name",
    entityText: "clusters",
    getEntityId: (cluster: ICluster) => cluster.uuid || "",
    intervalLabel: EIntervalLabels.ClusterIndex,
  },
};

export const useAdvancedTableDataIndexMapper: Record<EAdvancedIndexPages, IAdvancedIndexTableInfo> = {
  [EAdvancedIndexPages.DEPARTMENT]: {
    storageFilterKey: ETableFilters.DEPARTMENT,
    sortByDefault: EDepartmentColumnName.DepartmentName,
    entityText: "departments",
    getEntityId: (department: IDepartment) => department.id,
    allEntityColumns: departmentIndexColumns,
  },
  [EAdvancedIndexPages.PROJECT]: {
    storageFilterKey: ETableFilters.PROJECT,
    sortByDefault: EProjectColumnName.ProjectName,
    entityText: "projects",
    getEntityId: (e: IProject) => e.id || "",
    intervalLabel: EIntervalLabels.ProjectsIndex,
    intervalLabelPostCreation: EIntervalLabels.IndexPostCreationRefreshRate,
    allEntityColumns: projectIndexColumns,
  },
  [EAdvancedIndexPages.AUDIT]: {
    storageFilterKey: ETableFilters.AUDIT,
    sortByDefault: "subject",
    entityText: "audit events",
    getEntityId: (e: AuditLog) => e.id || "",
    allEntityColumns: auditIndexColumns,
  },
};
