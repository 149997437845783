<template>
  <runai-form-wrapper class="department-edit-form" :form-state="formData" ref="departmentForm">
    <runai-expansion-wrapper>
      <cluster-selection-section
        entity-name="department"
        :cluster-id="formData.clusterId"
        @update:cluster-id="updateClusterId"
        :disabled="!isNewDepartment"
        @is-section-invalid="invalidSection.departmentScope = !$event"
      />
      <department-name-section
        :read-only="readOnly || !isNewDepartment"
        v-model:department-name="formData.name"
        v-model:description="formData.description"
        :cluster-id="formData.clusterId"
        @is-section-invalid="invalidSection.departmentName = !$event"
      />
      <department-quota-management-section
        v-model:resources="formData.resources"
        :quota-statuses="quotaStatuses"
        :department="formData"
        :read-only="readOnly"
        :loading="loading"
        @is-section-invalid="invalidSection.quotaManagement = !$event"
      />
      <scheduling-rules-section
        v-if="showOrgUnitParity"
        :cluster-id="formData.clusterId"
        v-model:scheduling-rules="formData.schedulingRules"
        v-model:node-types="formData.nodeTypes"
        @is-section-invalid="invalidSection.schedulingRules = !$event"
        :entity="EQuotaEntity.department"
      />
      <department-form-footer-section
        :read-only="readOnly"
        :loading="submitting"
        :is-form-valid="isFormValid"
        @cancel="$emit('cancel')"
        @save="save"
      />
    </runai-expansion-wrapper>
  </runai-form-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";

// model

// cmps
import { ClusterSelectionSection } from "@/components/cluster/cluster-selection/cluster-selection-section/";
import { RunaiExpansionWrapper } from "@/components/common/runai-expansion-wrapper";
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";
import { DepartmentNameSection } from "@/components/department/department-edit-form/department-name-section/";
// util
import { deepCopy } from "@/utils/common.util";
import DepartmentQuotaManagementSection from "@/components/department/department-edit-form/department-quota-management-section/department-quota-management-section.vue";
import DepartmentFormFooterSection from "@/components/department/department-edit-form/department-form-footer-section/department-form-footer-section.vue";
import type { INodePoolsNameAndId } from "@/models/node-pool.model";
import { nodePoolService } from "@/services/control-plane/node-pool.service/node-pool.service";
import { useSettingStore } from "@/stores/setting.store";
import { DEPARTMENT_ROUTE_NAMES } from "@/router/department.routes/department.routes.names";
import type {
  AggregatedResources,
  Department,
  DepartmentCreationRequest,
  QuotaStatusNodePool,
  Resources,
} from "@/swagger-models/org-unit-service-client";
import { orgUnitUtil } from "@/utils/org-unit.util";
import { SchedulingRulesSection } from "@/components/project/project-edit-form/scheduling-rules-section";
import { unleashService } from "@/services/infra/unleash.service/unleash.service";
import { EQuotaEntity } from "@/models/resource.model";

interface ISectionValidation {
  departmentName: boolean;
  quotaManagement: boolean;
  departmentScope: boolean;
  schedulingRules: boolean;
}
export default defineComponent({
  components: {
    SchedulingRulesSection,
    ClusterSelectionSection,
    DepartmentFormFooterSection,
    DepartmentQuotaManagementSection,
    DepartmentNameSection,
    RunaiExpansionWrapper,
    RunaiFormWrapper,
  },
  emits: ["save", "cancel"],
  props: {
    department: {
      type: Object as PropType<Department | DepartmentCreationRequest>,
      required: true,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    submitting: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  provide() {
    return {
      projectsAggregatedResources: computed<AggregatedResources[]>(
        () => (this.formData as Department)?.projectsAggregatedResources || [],
      ),
      departmentResources: computed<Resources[]>(() => []),
    };
  },
  data() {
    return {
      formData: deepCopy(this.department) as Department | DepartmentCreationRequest,
      departmentForm: null as HTMLFormElement | null,
      invalidSection: {
        departmentName: false as boolean,
        quotaManagement: false as boolean,
        departmentScope: false as boolean,
        schedulingRules: false as boolean,
      } as ISectionValidation,
      isFormValid: true as boolean,
      loading: false as boolean,
    };
  },
  computed: {
    EQuotaEntity(): typeof EQuotaEntity {
      return EQuotaEntity;
    },
    isCpuEnabled(): boolean {
      return useSettingStore().isCPUResourcesQuotaEnabled;
    },
    quotaStatuses(): Array<QuotaStatusNodePool> {
      return (this.department as Department)?.status?.nodePoolQuotaStatuses || [];
    },
    isNewDepartment(): boolean {
      return this.$route.name === DEPARTMENT_ROUTE_NAMES.DEPARTMENT_NEW;
    },
    showOrgUnitParity(): boolean {
      return unleashService.showOrgUnitParity();
    },
  },
  methods: {
    async updateClusterId(clusterId: string): Promise<void> {
      if (this.formData.clusterId === clusterId || !clusterId) {
        return;
      }
      try {
        this.loading = true;
        this.formData.clusterId = clusterId;
        const nodePoolsNamesAndIds = await this.getNodePoolsNamesAndIds();
        this.formData.resources = orgUnitUtil.getEmptyResourcesModel(nodePoolsNamesAndIds);
      } catch (e) {
        console.error("failed to get cluster node pools", e);
      } finally {
        this.loading = false;
      }
    },
    async getNodePoolsNamesAndIds(): Promise<INodePoolsNameAndId[]> {
      const nodePools = await nodePoolService.getNodePools(this.formData.clusterId);
      return nodePools.map((nodePool) => ({
        id: nodePool.id.toString(),
        name: nodePool.name,
      }));
    },
    async save(): Promise<void> {
      const isValidationSuccess: boolean = await this.validate();
      this.isFormValid = isValidationSuccess;
      if (isValidationSuccess) {
        this.$emit("save", this.formData);
      }
    },
    async validate(): Promise<boolean> {
      return await (this.$refs.departmentForm as HTMLFormElement).validate();
    },
  },
  watch: {
    formData: {
      handler() {
        this.isFormValid = true;
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.department-edit-form {
  .create-department-form {
    .number-input {
      width: 220px;
    }
  }
}
</style>
