<template>
  <div class="row">
    <div class="col-1"></div>
    <div class="col-11">
      <div v-if="!isOnlyDefaultNodePool" class="row border-bottom items-center q-pb-xs">
        <div class="col" v-if="isProjectEntity">
          <div class="row">
            <div class="col-7">Order of priority</div>
            <div class="col-5">
              <runai-tooltip
                tooltip-text="The order of priority sets the order by which workloads will be scheduled to node pools if the order wasn't specified in each workload's job form. Note that if your admin set a policy with a different priority list, it will override this one."
              />
            </div>
          </div>
        </div>
        <div class="col">Node pool</div>
        <div class="col">{{ $options.RESOURCE_LABEL.GPU }}</div>
        <template v-if="isCpuEnabled">
          <div class="col">{{ $options.RESOURCE_LABEL.CPU }}</div>
          <div class="col">{{ $options.RESOURCE_LABEL.MEMORY }}</div>
        </template>
        <div class="col" v-if="isOverQuotaPriorityEnabled && isProjectEntity">
          <div class="row">
            <div class="col-7">Over quota priority</div>
            <div class="col-5">
              <runai-tooltip
                tooltip-text="Over quota priority sets the relative amount of additional unused resources that a project can get on top of its quota. This is dependent on the overall number of projects that requested quota overage and their priority level."
              />
            </div>
          </div>
        </div>
        <div class="col" v-else-if="isProjectEntity">
          Over quota
          <runai-tooltip
            tooltip-text="Node pools that have quota overage disabled are limited to using the GPUs assigned to them. Node pools that have it enabled can use GPUs beyond their quota, if needed and available."
          />
        </div>
      </div>
      <div v-else class="row border-bottom q-pb-md">
        <div class="col"></div>

        <div class="col">{{ $options.RESOURCE_LABEL.GPU }}</div>
        <template v-if="isCpuEnabled">
          <div class="col">{{ $options.RESOURCE_LABEL.CPU }}</div>
          <div class="col">{{ $options.RESOURCE_LABEL.MEMORY }}</div>
        </template>
        <div class="col" v-if="isOverQuotaPriorityEnabled && isProjectEntity">
          <div class="row">
            <div class="col-7">Over quota priority</div>
            <div class="col-5">
              <runai-tooltip
                tooltip-text="Over quota priority sets the relative amount of additional unused resources that a project can get on top of its quota. This is dependent on the overall number of projects that requested quota overage and their priority level."
              />
            </div>
          </div>
        </div>
        <div class="col" v-else-if="isProjectEntity">
          Over quota
          <runai-tooltip
            tooltip-text="Node pools that have quota overage disabled are limited to using the GPUs assigned to them. Node pools that have it enabled can use GPUs beyond their quota, if needed and available."
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
// cmps
import { RunaiTooltip } from "@/components/common/runai-tooltip";

import { EQuotaEntity, EResourceLabel } from "@/models/resource.model";

export default defineComponent({
  components: { RunaiTooltip },
  inject: ["entity"],
  RESOURCE_LABEL: EResourceLabel,
  props: {
    isCpuEnabled: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isOverQuotaPriorityEnabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isOnlyDefaultNodePool: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  computed: {
    isProjectEntity(): boolean {
      return this.entity === EQuotaEntity.project;
    },
    EQuotaEntity(): typeof EQuotaEntity {
      return EQuotaEntity;
    },
  },
});
</script>
<style lang="scss" scoped>
.border-bottom {
  border-bottom: 1px solid $black-12;
}
</style>
