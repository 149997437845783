import { unleashConfigService } from "./unleash-config.service";

export const unleashService = {
  shouldShowContainerPathOverride,
  isWorkloadDetailsTabEnabled,
  isHuggingFaceSubmitValidationEnabled,
  shouldShowNewLayout,
  showOrgUnitParity,
  showOrgUnitOverTimeData,
};

function shouldShowContainerPathOverride(): boolean {
  return unleashConfigService.isFeatureEnabled("release-19400-show-workload-container-path");
}

function isWorkloadDetailsTabEnabled(): boolean {
  return unleashConfigService.isFeatureEnabled("release-20600-show-workload-details-tab");
}

function isHuggingFaceSubmitValidationEnabled(): boolean {
  return unleashConfigService.isFeatureEnabled("release-17356-show-hugging-face-submit-validation");
}

function shouldShowNewLayout(): boolean {
  return unleashConfigService.isFeatureEnabled("release-19405-show-new-ui-navigation");
}

function showOrgUnitParity(): boolean {
  return unleashConfigService.isFeatureEnabled("release-18736-show-org-unity-parity");
}

function showOrgUnitOverTimeData(): boolean {
  return unleashConfigService.isFeatureEnabled("release-19487-show-org-unit-over-time-data");
}
