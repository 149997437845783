export enum EAccessOptions {
  EVERYONE = "everyone",
  GROUPS = "groups",
  SPECIFIC_USERS = "specific-users",
  PRIVATE = "private",
}

export interface IAccessOption {
  label: string;
  value: string;
  description?: string;
  aid: string;
}

export interface IToolAccess {
  authorizedUsers?: Array<string> | null;
  authorizedGroups?: Array<string> | null;
}

export enum EToolLabel {
  UserEmailOrName = "User email or name",
  GroupName = "Group name",
}
