import { type IWorkloadCreateFormConfig, EWorkloadSectionNames } from "./workload-create-form.model";
import type { IUIWorkloadCreation } from "@/models/workload.model";
import { ENodePoolsListOrigin, type IComputeSectionNodePoolData } from "@/components/section/compute-resource-section";
import type { INodePoolResources } from "@/models/project.model";

export const workloadCreateFormUtil = {
  getWorkloadSectionDefaultsConfig,
  getComputeResourceData,
};

function getWorkloadSectionDefaultsConfig(sectionNames: Array<EWorkloadSectionNames>): IWorkloadCreateFormConfig {
  const defaultsOptions: IWorkloadCreateFormConfig = _getDefaultsOptions();

  if (sectionNames.length === 0) return {};

  return sectionNames.reduce((config: IWorkloadCreateFormConfig, sectionName: EWorkloadSectionNames) => {
    if (defaultsOptions[sectionName]) {
      // @ts-ignore
      config[sectionName] = defaultsOptions[sectionName];
    }

    return config;
  }, {});
}

function _getDefaultsOptions(): IWorkloadCreateFormConfig {
  const defaultsOptions: IWorkloadCreateFormConfig = {
    [EWorkloadSectionNames.ToggleEditingForm]: {
      sectionOptions: {
        mainTitle: "",
      },
    },
    [EWorkloadSectionNames.Cluster]: {
      sectionDefaultOpen: false,
    },
    [EWorkloadSectionNames.Project]: {
      projects: [],
    },
    [EWorkloadSectionNames.MultiNode]: {
      sectionDefaultOpen: false,
    },
    [EWorkloadSectionNames.WorkloadName]: {
      sectionDefaultOpen: false,
    },
    [EWorkloadSectionNames.Credentials]: {
      sectionDefaultOpen: true,
    },
    [EWorkloadSectionNames.ModelName]: {
      sectionDefaultOpen: true,
    },
    [EWorkloadSectionNames.Environment]: {
      environments: [],
      sectionDefaultOpen: true,
      sectionOptions: {
        canAddEnvVariable: true,
        isToolBoxRequired: true,
      },
    },
    [EWorkloadSectionNames.Compute]: {
      sectionDefaultOpen: true,
      isRequired: true,
      computeResources: [],
      sectionOptions: {
        applyPolicyDefaults: false,
      },
    },
    [EWorkloadSectionNames.DataSource]: {
      dataSources: [],
    },
    [EWorkloadSectionNames.Volumes]: {
      storageClasses: [],
      sectionDefaultOpen: false,
    },
    [EWorkloadSectionNames.General]: {
      sectionDefaultOpen: false,
    },
  };

  return defaultsOptions;
}

function getComputeResourceData(workload: IUIWorkloadCreation, nodePoolsResources: INodePoolResources[] = []) {
  const nodePoolsData: IComputeSectionNodePoolData | null = _getNodePoolsData(workload, nodePoolsResources);

  return {
    computeResourceId: workload.assets.compute || null,
    nodeType: workload.specificEnv?.nodeType || null,
    nodePools: nodePoolsData,
    workers: workload.distributed?.numWorkers || null,
    autoScaleData: workload.specificEnv?.autoScaleData || undefined,
    tolerations: workload.specificEnv?.tolerations,
    podAffinity: workload.specificEnv?.podAffinity,
  };
}

function _getNodePoolsData(
  workload: IUIWorkloadCreation,
  nodePoolsResources: INodePoolResources[] = [],
): IComputeSectionNodePoolData | null {
  if (!workload.specificEnv || !workload.specificEnv.nodePools) {
    return null;
  }

  const allNodePoolsOptions: Array<string> = nodePoolsResources.map(
    (nodePoolResource: INodePoolResources) => nodePoolResource.nodePool.name,
  );

  return {
    defaultNodePools: workload.specificEnv.nodePools,
    allNodePoolsOptions,
    nodePoolsListOrigin: ENodePoolsListOrigin.Project,
  };
}
