import type { IAssetsFilter } from "@/models/filter.model";
import type { ITableColumn } from "@/models/table.model";
import { filterService } from "@/services/filter.service/filter.service";
import { onUnmounted, ref, type Ref } from "vue";
import { useQuasar } from "quasar";
import { alertUtil } from "@/utils/alert.util";
import { useTableDataIndexMapper, EIndexPages } from "./helpers/use-table-data.mapper";
import { useRoute } from "vue-router";
import { deepCopy } from "@/utils/common.util";
import { type EIntervalLabels, EIntervalTime } from "@/models/interval.model";
import { intervalUtil } from "@/utils/interval.util";
import { useClusterStore } from "@/stores/cluster.store";
/**
 * Composable function to manage table data and filters.
 *
 * @template T - The type of the table data.
 * @param {CallableFunction} loadListFunc - Function to load the list of entities.
 * @param {Array<ITableColumn>} tableColumns - Array of table columns.
 * @param {EIndexPages} indexPage - The index page identifier.
 * @param {CallableFunction} [secondaryLoadFunc] - Optional function for loading additional info from other sources without delaying table loading.
 * @returns {Object} - An object containing methods and reactive properties for managing table data.
 */
export function useTableData<T>(
  loadListFunc: CallableFunction,
  tableColumns: Array<ITableColumn>,
  indexPage: EIndexPages,
  secondaryLoadFunc?: CallableFunction,
) {
  const clusterStore = useClusterStore();
  const $q = useQuasar();

  const $route = useRoute();

  const tableData: Ref<T[]> = ref([]);
  const filterBy: Ref<IAssetsFilter> = ref({});
  const loadingTableData: Ref<boolean> = ref(false);
  const loadingError: Ref<boolean> = ref(false);
  const decelerateRefreshRateTimeoutId: Ref<null | number> = ref(null);
  const columns = ref(tableColumns);
  const lastCreatedEntity: Ref<null | T> = ref(null);
  const isFirstLoad = ref(true);

  //interval
  const intervalLabel: EIntervalLabels | undefined = useTableDataIndexMapper[indexPage].intervalLabel;
  const intervalLabelPostCreation: EIntervalLabels | undefined =
    useTableDataIndexMapper[indexPage].intervalLabelPostCreation;

  const listEntities = async (withLoading = false): Promise<T[]> => {
    try {
      if (withLoading) loadingTableData.value = true;

      const loadFunc = getLoadFunc();
      if (isFirstLoad.value) {
        isFirstLoad.value = false;
      }

      return await loadFunc(filterBy.value);
    } catch (e: unknown) {
      console.error(e);
      $q.notify(alertUtil.getError(`Failed to load ${useTableDataIndexMapper[indexPage].entityText}`));
      loadingError.value = true;
      throw e;
    } finally {
      if (withLoading) loadingTableData.value = false;
    }
  };

  const refreshList = async (withLoading = false) => {
    const refreshedList: T[] = await listEntities(withLoading);

    const lastCreatedId = $route.query.createdEntityId?.toString() || null;
    // if createdEntityId exists set the lastCreatedEntity and remove it from the list
    if (lastCreatedId) {
      const entityIndexInList: number = refreshedList.findIndex((e) => {
        const id = useTableDataIndexMapper[indexPage].getEntityId(e);
        return id === lastCreatedId;
      });

      if (entityIndexInList !== -1) {
        lastCreatedEntity.value = refreshedList[entityIndexInList];
      } else {
        clearLastCreated();
      }
    }

    tableData.value = filterService.filterListByTableFilters(refreshedList, filterBy.value, columns.value);
  };

  const loadFilters = () => {
    const defaultFilters: IAssetsFilter = filterService.getDefaultFilters(
      useTableDataIndexMapper[indexPage].sortByDefault,
      columns.value,
    );

    const searchParamsFilters: IAssetsFilter = filterService.loadFilters(
      window.location,
      useTableDataIndexMapper[indexPage].storageFilterKey,
      defaultFilters,
    );
    filterBy.value = searchParamsFilters;
  };

  const setFilterBy = async (
    newFilter: IAssetsFilter,
    keyChanged: null | string = null,
    forceLoad = true,
    clearTopRow = true,
  ) => {
    filterBy.value = newFilter;
    const preventRefresh = !forceLoad || keyChanged === "displayedColumns";
    filterService.saveFilters(useTableDataIndexMapper[indexPage].storageFilterKey, filterBy.value);

    if (filterBy.value.columnFilters) {
      const cluster = filterService.getClusterUuidQueryFromColumnFilter(filterBy.value.columnFilters);
      clusterStore.setSelectedClusterById(cluster.selectedClusterId || "");
    }

    if (preventRefresh) return;
    if (clearTopRow) clearLastCreated();
    refreshList(true);
  };

  /*
   * @param entity - the entity to update
   * @param key - the key of the entity to update
   * @param newValue - the new value to update the entity with
   * use this function to update a cell content in the table, instead of reloading the whole table
   */
  const updateCellContent = <K extends keyof T>(entity: T, key: K, newValue: T[K]) => {
    if (lastCreatedEntity.value && _isLastCreatedEntity(entity)) {
      lastCreatedEntity.value[key] = deepCopy(newValue) as NonNullable<T>[K];
    } else {
      const rowIndex = _getRowIndex(entity);

      const row: T = tableData.value[rowIndex];

      if (!row) {
        throw new Error(`Invalid key: ${String(key)}`);
      }

      tableData.value.splice(rowIndex, 1, { ...row, [key]: deepCopy(newValue) } as T);
    }
  };

  /*
   * @param entity - the entity to remove from the table
   * use this function to remove a row from the table, instead of reloading the whole table
   * always remove the last created entity from the table upon deletion
   */
  const removeRow = (entity: T) => {
    if (!_isLastCreatedEntity(entity)) {
      const rowIndex = _getRowIndex(entity);
      tableData.value.splice(rowIndex, 1);
    }
    clearLastCreated();
  };

  const clearFilters = (forcedDefaultFilter?: IAssetsFilter) => {
    if (forcedDefaultFilter) {
      setFilterBy(forcedDefaultFilter);
      return;
    }
    setFilterBy({
      ...filterBy.value,
      columnFilters: [],
      searchTerm: "",
    });
  };

  // this func is essentialy the init func of the composable, its here for the purpose of passing special filters which are unique to specific apis (such as usageInfo: true etc...)
  const initTableFilter = (specificFilterVals: IAssetsFilter = {}) => {
    loadFilters();
    setFilterBy({ ...filterBy.value, ...specificFilterVals }, null, true, false);
    if (intervalLabel) _setRefreshRate();

    // we want to trigger refresh in order to get the full table data
    if (secondaryLoadFunc) refreshList();
  };

  const _setRefreshRate = () => {
    if (!intervalLabel) return;

    if (!intervalLabelPostCreation || !$route.query.createdEntityId) {
      intervalUtil.startInterval(intervalLabel, refreshList);
      return;
    }

    intervalUtil.startInterval(intervalLabelPostCreation, refreshList);

    decelerateRefreshRateTimeoutId.value = window.setTimeout(() => {
      clearRefreshInterval();
      intervalUtil.startInterval(intervalLabel, refreshList);
    }, EIntervalTime.OneMinute);
  };

  const clearLastCreated = () => {
    if ($route.query.createdEntityId) delete $route.query.createdEntityId;
    lastCreatedEntity.value = null;
  };

  const clearRefreshInterval = () => {
    intervalLabel && intervalUtil.stopInterval(intervalLabel);
    intervalLabelPostCreation && intervalUtil.stopInterval(intervalLabelPostCreation);
  };

  const clearDecelerateTimeout = () => {
    decelerateRefreshRateTimeoutId.value && window.clearTimeout(decelerateRefreshRateTimeoutId.value);
  };

  //columns
  const setColumns = (newColumns: Array<ITableColumn>) => {
    columns.value = newColumns;
  };

  onUnmounted(() => {
    clearRefreshInterval();
    clearDecelerateTimeout();
  });

  /*
   * helper functions
   */
  const _getRowIndex = (entity: T): number => {
    const rowIndex = tableData.value.findIndex((e: T) => {
      const id = useTableDataIndexMapper[indexPage].getEntityId(e);
      return id === useTableDataIndexMapper[indexPage].getEntityId(entity);
    });
    if (rowIndex < 0 || rowIndex >= tableData.value.length) {
      throw new Error("Invalid row index");
    }
    return rowIndex;
  };

  const _isLastCreatedEntity = (entity: T): boolean => {
    if (!lastCreatedEntity.value) return false;
    return (
      useTableDataIndexMapper[indexPage].getEntityId(entity) ===
      useTableDataIndexMapper[indexPage].getEntityId(lastCreatedEntity.value)
    );
  };

  // Use secondaryLoadFunc for subsequent loads if provided
  const getLoadFunc = (): CallableFunction => {
    const shouldUseSecondaryLoadFunc = secondaryLoadFunc && !isFirstLoad.value;
    return shouldUseSecondaryLoadFunc ? secondaryLoadFunc : loadListFunc;
  };

  return {
    initTableFilter,
    setFilterBy,
    clearFilters,
    refreshList,
    tableData,
    filterBy,
    loadingTableData,
    loadingError,
    lastCreatedEntity,
    clearLastCreated,
    updateCellContent,
    removeRow,
    setColumns,
    columns,
  };
}
