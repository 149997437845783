<template>
  <runai-base-dialog
    class="access-rule-management-modal"
    :model-value="true"
    @close="$emit('close')"
    size="width-md"
    no-padding
  >
    <template #header>{{ header }} </template>
    <template #body>
      <q-linear-progress v-if="loading" indeterminate />
      <q-form>
        <q-card>
          <q-card-section>
            <div class="sub-header">{{ subheader }}</div>
            <access-rule-item
              ref="accessRuleItem"
              v-for="(accessRule, i) in accessRules"
              :key="accessRule.subjectId"
              :access-rule-item="accessRule"
              :display-config="displayConfig"
              :subject-options="subjects"
              :role-options="rolesOptions"
              :page="modalOptions.page"
              :loading="submitting"
              :is-inherited-from-group="accessRule.isInherited"
              :is-closing-access-rule="isClosingAccessRule"
              @save-rule="saveRule(i)"
              @delete-rule="deleteRule(accessRule.accessRuleId, i)"
              @update="updateAccessRuleInputs($event, i)"
              @is-valid="setIsValid"
            />

            <q-btn
              :disable="disableAccessRuleBtn"
              v-permission="{ resourceType: ResourceType.AccessRules, action: Action.Create }"
              @click="addAccessRuleItem"
              flat
              color="primary"
              class="q-mt-md"
              label="+ ACCESS RULE"
            ></q-btn>
          </q-card-section>
        </q-card>
      </q-form>
    </template>

    <template #footer-left>
      <span v-if="displayFormHint" class="text-negative q-pl-sm">
        {{ $options.errorMessage }}
      </span>
    </template>
    <template #footer>
      <q-btn label="Close" color="primary" @click="handleClose" />
    </template>
  </runai-base-dialog>
</template>
<script lang="ts">
import { type Component, defineComponent, type PropType } from "vue";
//cmps
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";
import { AccessRuleItem } from "@/components/rbac/access-rule/access-rule-management-modal/access-rule-item/";
//model
import type {
  IAccessRuleDisplayConfig,
  IAccessRuleItem,
  IAccessRuleManagementModalOptions,
} from "@/models/access-rule.model";
import { EAccessRuleModalPage, subjectTypeOptions, subjectTypeOptionsWithSSO } from "@/models/access-rule.model";
import {
  Action,
  ResourceType,
  ScopeType,
  SubjectType,
  type AccessRuleCreationFields,
  type Role,
  type AccessRule,
} from "@/swagger-models/authorization-client";
import type { ISelectOption } from "@/models/global.model";

//service
import { accessRuleService } from "@/services/control-plane/rbac/access-rule.service/access-rule.service";
import { rolesService } from "@/services/control-plane/rbac/roles.service/roles.service";
//store
import { usePermissionStore } from "@/stores/permissions.store";
import { useRoleStore } from "@/stores/role.store";
import { useAuthStore } from "@/stores/auth.store";
import { useUserStore } from "@/stores/user.store";
import { errorMessages } from "@/common/error-message.constant";

//util
import { accessRuleUtil } from "@/utils/rbac.util/access-rule.util/access-rule.util";
import { alertUtil } from "@/utils/alert.util";
import {
  BadGatewayError,
  ConflictError,
  HttpErrorResponse,
  InternalServerError,
  NotImplementedError,
} from "@/models/http-response.model";
import type { IAccessRulesFilter } from "@/services/control-plane/rbac/access-rule.service/access-rule.service";
import type { IUser } from "@/models/user.model";

export default defineComponent({
  name: "access-rule-management-modal",
  components: { AccessRuleItem, RunaiBaseDialog },
  errorMessage: errorMessages.FORM_INCOMPLETE,
  emits: ["close", "access-rule-created", "access-rule-deleted"],
  props: {
    modalOptions: {
      type: Object as PropType<IAccessRuleManagementModalOptions>,
      required: true,
    },
  },
  data() {
    return {
      roleStore: useRoleStore(),
      permissionStore: usePermissionStore(),
      userStore: useUserStore(),
      authStore: useAuthStore(),
      displayConfig: {} as IAccessRuleDisplayConfig,
      header: accessRuleUtil.getAccessRuleModalHeader(this.modalOptions) as string,
      subheader: accessRuleUtil.getAccessRuleModalSubHeader(this.modalOptions.page) as string,
      loading: false as boolean,
      submitting: false as boolean,
      accessRules: [] as IAccessRuleItem[],
      displayFormHint: false as boolean,
      timeOutId: null as ReturnType<typeof setTimeout> | null,
      isFormValid: false as boolean,
      isClosingAccessRule: false as boolean,
    };
  },
  async created() {
    try {
      this.loading = true;
      if (this.hasReadRolesPermission) {
        await this.roleStore.loadRoles();
      }
      await this.loadAccessRules();
      this.displayConfig = accessRuleUtil.getAccessRuleModalDisplayConfig(this.modalOptions.page);
    } catch (error: unknown) {
      this.handleError(error, "Failed to load access rules");
    } finally {
      this.loading = false;
    }
  },
  computed: {
    disableAccessRuleBtn(): boolean {
      return this.loading || !this.hasReadRolesPermission;
    },
    hasReadRolesPermission(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Roles, Action.Read);
    },
    Action(): typeof Action {
      return Action;
    },
    ResourceType(): typeof ResourceType {
      return ResourceType;
    },
    subjects(): ISelectOption[] {
      return this.isSso ? subjectTypeOptionsWithSSO : subjectTypeOptions;
    },
    roles(): Role[] {
      return this.roleStore.roleList.filter((role: Role) =>
        rolesService.isPermittedRole(role, this.permissionStore.permissionsList),
      );
    },
    rolesOptions(): ISelectOption[] {
      return this.roles.map((role: Role) => ({
        label: role.name,
        value: role.id,
      }));
    },
    isSso(): boolean {
      return this.authStore.isSSO;
    },
    isScopePage(): boolean {
      return accessRuleUtil.isScopePage(this.modalOptions.page);
    },
    subjectIds(): string[] {
      const user: IUser = this.modalOptions.user as IUser;
      return user
        ? accessRuleUtil.getUserSubjectIds(user)
        : this.modalOptions.subjectId
        ? [this.modalOptions.subjectId]
        : [];
    },
  },
  methods: {
    async loadAccessRules(): Promise<void> {
      const accessRuleFilter: IAccessRulesFilter = {
        scopeType: this.modalOptions.scopeType,
        scopeId: this.modalOptions.scopeId,
        subjectIds: this.subjectIds,
      };
      if (!this.modalOptions.user?.groups) {
        accessRuleFilter.subjectType = this.modalOptions.subjectType;
      }
      const accessRulesRecords = await accessRuleService.getAccessRules(accessRuleFilter);
      this.accessRules = accessRulesRecords.accessRules
        .map((accessRule: AccessRule): IAccessRuleItem => {
          return {
            accessRuleId: accessRule.id,
            scopeName: accessRule.scopeName,
            scopeType: accessRule.scopeType,
            subjectId: accessRule.subjectId,
            subjectType: accessRule.subjectType,
            roleName: accessRule.roleName,
            subjectTypeOption: null,
            roleOption: null,
            scopeInput: null,
            saved: true,
            isInherited: this.modalOptions.user?.groups && accessRule.subjectType === SubjectType.Group,
          };
        })
        .sort((a, b) => (a.isInherited === b.isInherited ? 0 : a.isInherited ? -1 : 1));
    },
    updateAccessRuleInputs(inputs: IAccessRuleItem, index: number): void {
      this.accessRules.splice(index, 1, inputs);
    },
    async saveRule(index: number): Promise<void> {
      this.submitting = true;
      const accessRuleRequest = this.getAccessRuleCreationRequest(index);
      try {
        const response: AccessRule = await accessRuleService.createAccessRule(accessRuleRequest);
        if (this.accessRules.some((accessRule: IAccessRuleItem) => accessRule.accessRuleId === response.id)) {
          this.$q.notify(alertUtil.getSuccess("Access rule already exists"));
        } else {
          this.accessRules[index].scopeName = response.scopeName;
          this.accessRules[index].roleName = response.roleName;
          this.accessRules[index].accessRuleId = response.id;
          this.accessRules[index].subjectType = response.subjectType;
          this.accessRules[index].scopeType = response.scopeType;
          this.accessRules[index].saved = true;
          this.$emit("access-rule-created", response);
          this.$q.notify(alertUtil.getSuccess("Access rule created successfully"));
        }
      } catch (error: unknown) {
        this.handleError(error, "Failed to delete access rule");
      } finally {
        this.submitting = false;
      }
    },
    getAccessRuleCreationRequest(index: number): AccessRuleCreationFields {
      const subjectType = this.getSubjectType(index);
      const scopeId = this.getScopeId(index);
      const scopeType = this.getAccessRuleScopeType(index);
      const subjectId = this.getSubjectId(index);
      //roleId must be number at this stage since validation passed
      const roleId = this.accessRules[index].roleOption?.value || -1;
      return {
        scopeId,
        scopeType,
        subjectType,
        subjectId,
        roleId,
      };
    },
    getSubjectType(index: number): SubjectType {
      if (this.isScopePage) {
        return this.accessRules[index].subjectTypeOption?.value as SubjectType;
      }
      return this.modalOptions.subjectType as SubjectType;
    },
    getScopeId(index: number): string {
      if (this.isScopePage) {
        return this.modalOptions.scopeId || "";
      }
      return this.accessRules[index].scopeInput?.id || "";
    },
    getSubjectId(index: number): string {
      if (this.isScopePage) {
        return this.accessRules[index].subjectId;
      }
      return this.modalOptions.subjectId || "";
    },
    getAccessRuleScopeType(index: number): ScopeType {
      switch (this.modalOptions.page) {
        case EAccessRuleModalPage.Project:
          return ScopeType.Project;
        case EAccessRuleModalPage.Department:
          return ScopeType.Department;
        case EAccessRuleModalPage.Application:
          return this.accessRules[index].scopeInput?.type as ScopeType;
        case EAccessRuleModalPage.User:
          return this.accessRules[index].scopeInput?.type as ScopeType;
      }
    },
    addAccessRuleItem(): void {
      const emptyRule: IAccessRuleItem = {
        subjectTypeOption: null,
        subjectId: this.modalOptions.subjectId as string,
        scopeName: this.modalOptions.scopeName,
        roleOption: null,
        scopeInput: null,
        saved: false,
      };
      this.accessRules.push(emptyRule);
    },
    async deleteRule(accessRuleId: number | undefined, index: number): Promise<void> {
      try {
        this.loading = true;
        if (accessRuleId) {
          await accessRuleService.deleteAccessRule(accessRuleId);
          this.$q.notify(alertUtil.getSuccess("Access rule deleted successfully"));
        }
        this.$emit("access-rule-deleted", index);
        this.accessRules.splice(index, 1);
      } catch (error: unknown) {
        this.handleError(error, "Failed to delete access rule");
      } finally {
        this.loading = false;
      }
    },
    async validateAccessRuleForm(accessRuleIndex: number): Promise<boolean> {
      const accessRuleItem: Component[] | undefined = this.$refs["accessRuleItem"] as Component[];

      if (accessRuleItem && accessRuleItem[accessRuleIndex]) {
        //@ts-ignore
        const form = (accessRuleItem[accessRuleIndex] as Component).$refs["accessRuleForm"] as HTMLFormElement;

        if (form) {
          return await form.validate();
        }
      }
      return false;
    },
    setIsValid(isValid: boolean): void {
      this.isFormValid = isValid;
    },
    showHint(): void {
      this.displayFormHint = true;
      this.timeOutId && clearTimeout(this.timeOutId);
      this.timeOutId = setTimeout(() => (this.displayFormHint = false), 15000);
    },
    handleError(error: unknown, customMessage: string): void {
      const serverErrors = [InternalServerError, NotImplementedError, BadGatewayError];

      if (error instanceof HttpErrorResponse && !serverErrors.some((serverError) => error instanceof serverError)) {
        if (error instanceof ConflictError) {
          this.$q.notify(alertUtil.getError("Access rule already exists"));
        } else {
          this.$q.notify(alertUtil.getError(error.message));
        }
        console.error(error.serialize());
      } else {
        console.error(error);
        this.$q.notify(alertUtil.getError(customMessage));
      }
    },
    async handleClose(): Promise<void> {
      this.isFormValid = true;
      this.isClosingAccessRule = true;

      for (const accessRuleItem of this.accessRules) {
        const index = this.accessRules.indexOf(accessRuleItem);
        if (!accessRuleItem.saved) {
          this.isFormValid = false;
          await this.validateAccessRuleForm(index);
        }
      }

      if (this.isFormValid) {
        this.$emit("close");
      } else {
        this.showHint();
      }
    },
  },
  watch: {
    isFormValid(valid: boolean) {
      if (valid) {
        this.displayFormHint = false;
      } else {
        this.showHint();
      }
    },
  },
  unmounted() {
    this.timeOutId && clearTimeout(this.timeOutId);
  },
});
</script>
<style lang="scss">
.access-rule-management-modal {
  .dialog-content-container {
    width: 813px !important;
  }
}
</style>
<style scoped lang="scss">
.sub-header {
  margin-top: 2px;
  margin-bottom: 16px;
}
</style>
