// Stores
import { defineStore } from "pinia";

// Models
import type {
  WorkloadCreationRequest,
  Inference,
  InferenceCreationRequestV2,
  InferenceV2,
} from "@/swagger-models/assets-service-client";
import type { IUIInferenceCreation } from "@/models/workload.model";

// utils
import { workloadUtil } from "@/utils/workload.util/workload.util";

import { inferenceService } from "@/services/control-plane/inference.service/inference.service";

export const useInferenceStore = defineStore("Inference", {
  state: () => ({
    inference: workloadUtil.getEmptyUIInferenceCreation() as IUIInferenceCreation,
    selectedInference: null as Inference | null,
    originRouteName: "" as string,
  }),
  getters: {
    inferenceName(): string {
      return this.inference.name || "";
    },
  },
  actions: {
    async createInference(inference: WorkloadCreationRequest): Promise<Inference> {
      return await inferenceService.create(inference);
    },
    async createInferenceV2(inference: InferenceCreationRequestV2): Promise<InferenceV2> {
      return await inferenceService.createInferenceV2(inference);
    },
    setInference(inference: IUIInferenceCreation): void {
      this.inference = inference;
    },
    resetCreateInferenceData(): void {
      this.inference = workloadUtil.getEmptyUIInferenceCreation();
    },
    setOriginRoutePage(name: string): void {
      this.originRouteName = name;
    },
  },
});
