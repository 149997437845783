<template>
  <section class="runai-change-password-modal">
    <runai-base-dialog :model-value="true" @close="$emit('close')" size="width-sm" :no-padding="true">
      <template #header>Change Password</template>

      <template #body>
        <div class="change-password-form-container">
          <div class="field no-rules">
            <label class="field-label">Current Password</label>
            <q-input dense outlined type="password" v-model="currentPassword" aid="current-password" no-error-icon />
          </div>
          <div class="field">
            <label class="field-label">New Password</label>
            <q-input
              outlined
              dense
              type="password"
              v-model="password"
              :rules="[isValidPassword]"
              aid="new-password"
              no-error-icon
            />
          </div>
          <div class="field">
            <label class="field-label">Confirm Password</label>
            <q-input
              outlined
              dense
              type="password"
              v-model="verifyPassword"
              aid="verify-password"
              no-error-icon
              :rules="[verifyPasswordValidation]"
            />
          </div>
        </div>
      </template>

      <template #footer>
        <q-btn label="Cancel" color="primary" flat @click="$emit('close')" aid="cancel-button" />
        <q-btn
          label="Change Password"
          @click="reset"
          color="primary"
          aid="reset-password-button"
          :disable="isResetDisabled"
          :loading="processing"
        />
      </template>
    </runai-base-dialog>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "@vue/runtime-core";

// cmps
import { RunaiBaseDialog } from "@/components/common/runai-base-dialog";

// stores
import { useAuthStore } from "@/stores/auth.store";

// services
import { authService } from "@/services/control-plane/auth.service/auth.service";

// utils
import { alertUtil } from "@/utils/alert.util";

// others
import { errorMessages } from "@/common/error-message.constant";
import { isValidPassword, isEmpty } from "@/common/form.validators";

export default defineComponent({
  components: {
    RunaiBaseDialog,
  },
  emits: ["close"],
  props: {
    reloadUser: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      authStore: useAuthStore(),
      currentPassword: "" as string,
      password: "" as string,
      verifyPassword: "" as string,
      processing: false as boolean,
    };
  },
  computed: {
    isResetDisabled(): boolean {
      return (
        isEmpty(this.currentPassword) ||
        isEmpty(this.password) ||
        isEmpty(this.verifyPassword) ||
        this.password !== this.verifyPassword
      );
    },
  },
  methods: {
    isValidPassword(val: string): boolean | string {
      return isValidPassword(val) || errorMessages.INVALID_PASSWORD_FORMAT;
    },
    verifyPasswordValidation(val: string): boolean | string {
      return this.password === val || errorMessages.PASSWORD_DOES_NOT_MATCH;
    },
    async reset() {
      try {
        this.processing = true;
        await authService.changePassword(this.password, this.currentPassword);
        if (this.reloadUser) {
          await this.authStore.loadUserInfo();
        }
        this.$q.notify(alertUtil.getSuccess("Change password succeeded!"));
        this.$emit("close");
      } catch (error: unknown) {
        this.$q.notify(alertUtil.getError("Change password failed!"));
      } finally {
        this.processing = false;
      }
    },
  },
});
</script>

<style lang="scss">
.change-password-form-container {
  padding: 15px 25px 0 25px;

  .field {
    width: 450px;
    padding: 0 0 15px 0;

    &.no-rules {
      padding: 0 0 25px 0;
    }

    .field-label {
      font-size: 16px;
      margin: 0 0 5px 0;
      display: block;
    }
  }
}
</style>
