<template>
  <runai-expansion-item
    label="Project"
    :default-opened="isSelectable"
    :hide-expend-icon="!isSelectable"
    :disable-opening="!isSelectable"
    :section-invalid="sectionInvalid"
    aid="asset-project-section"
  >
    <template #subheader>
      <div class="row items-center justify-between q-pr-sm">
        <span>{{ summary }}</span>
        <slot name="custom-button"></slot>
      </div>
    </template>
    <section v-if="isSelectable" class="column q-py-md">
      <div class="q-mb-md" v-if="kindOfAsset">Set which project can use this {{ kindOfAsset }}</div>
      <div class="row">
        <runai-select
          class="col-8 project-select"
          :options="projectOptions"
          :model-value="selectedProject"
          placeholder="Select a project..."
          :rules="[required]"
          @update:model-value="updateSelectedProject($event)"
          aid="project-select"
        />
      </div>
    </section>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Constants
import { errorMessages } from "@/common/error-message.constant";
// Models
import type { ISelectOption } from "@/models/global.model";
import type { IProject } from "@/models/project.model";
import { Scope } from "@/swagger-models/assets-service-client";
// Components
import { RunaiSelect } from "../common/runai-select";
import { RunaiExpansionItem } from "../common/runai-expansion-item";

export default defineComponent({
  components: {
    RunaiExpansionItem,
    RunaiSelect,
  },
  emits: ["project-selected", "is-section-invalid"],
  props: {
    isSelectable: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    projects: {
      type: Array as PropType<Array<IProject>>,
      required: false,
    },
    allowSelectTenant: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    hasCreateTenantPermission: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    selectedProjectId: {
      type: [Number, null] as PropType<number | null>,
      required: false,
    },
    kindOfAsset: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    return {
      selectedProject: null as ISelectOption | null,
    };
  },
  created() {
    if (this.selectedProjectId !== undefined) {
      this.selectedProject =
        this.projectOptions.find((option: ISelectOption) => {
          return option.value === this.selectedProjectId;
        }) || null;
    }
    this.selectedProject && this.updateSelectedProject(this.selectedProject);
    if (this.projectOptions.length === 1) {
      this.updateSelectedProject(this.projectOptions[0]);
    }
  },
  computed: {
    projectOptions(): Array<ISelectOption> {
      const options: Array<ISelectOption> =
        this.projects?.map((project: IProject) => ({ label: project.name, value: project.id })) || [];
      if (this.hasCreateTenantPermission && this.allowSelectTenant) {
        options.unshift({ label: "All (future and current projects)", value: null });
      }
      return options;
    },
    summary(): string {
      return this.projectName || this.selectedProject?.label || "None";
    },
    sectionInvalid(): boolean {
      return !this.selectedProject;
    },
    projectName(): string {
      return this.projectOptions.find((option: ISelectOption) => option.value === this.selectedProjectId)?.label || "";
    },
  },
  methods: {
    required(val: ISelectOption): boolean | string {
      return !!val || errorMessages.SELECT_PROJECT;
    },
    updateSelectedProject(selectedProject: ISelectOption | null): void {
      if (!selectedProject) return;
      this.selectedProject = selectedProject;
      const projectId: number | null = selectedProject.value as number | null;
      const scope: Scope = this.selectedProject.value ? Scope.Project : Scope.Tenant;
      this.$emit("project-selected", { scope, projectId });
    },
  },
  watch: {
    sectionInvalid: {
      handler(newVal: boolean): void {
        this.$emit("is-section-invalid", newVal);
      },
      immediate: true,
    },
  },
});
</script>

<style scoped lang="scss">
.select-placeholder {
  opacity: 0.7;
}
</style>
