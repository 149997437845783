<template>
  <runai-delete-modal @delete="$emit('delete')" @cancel="$emit('cancel')" :deleting="isDepartmentDeleting">
    <template #title>
      <p class="delete-modal-msg q-mt-md">
        Delete department {{ currentDepartment.name }}
        <span v-if="hasProjects">
          and its
          <q-btn class="no-padding" flat no-caps color="primary"
            >subordinate projects
            <runai-org-tree-with-pop-over v-if="departmentScope" readonly :selected="departmentScope"
          /></q-btn>
        </span>
        ?
      </p>
    </template>
    <template #description>{{ description }} </template>
  </runai-delete-modal>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "@vue/runtime-core";
//cmps
import { RunaiDeleteModal } from "@/components/common/runai-delete-modal/";
import { RunaiOrgTreeWithPopOver } from "@/components/common/runai-org-tree/with-popover";
// models
import type { IDepartmentTable } from "@/models/department.model";
import type { IOrgTreeNodeId } from "@/models/org-tree.model";
import { ScopeType } from "@/swagger-models/authorization-client";

export default defineComponent({
  components: {
    RunaiOrgTreeWithPopOver,
    RunaiDeleteModal,
  },
  emits: ["cancel", "delete"],
  props: {
    currentDepartment: {
      type: Object as PropType<IDepartmentTable>,
      required: true,
    },
    isDepartmentDeleting: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    departmentScope(): IOrgTreeNodeId | null {
      if (!this.currentDepartment.id) return null;
      return {
        type: ScopeType.Department,
        id: this.currentDepartment.id,
      };
    },
    hasProjects(): boolean {
      return !!this.currentDepartment.children?.length;
    },
    description(): string {
      return this.hasProjects
        ? `The department and its subordinate projects will be permanently deleted. This can't be undone.`
        : "Once a department is deleted, it cannot be restored.";
    },
  },
});
</script>

<style>
.delete-modal-msg {
  font-size: 16px;
}
.delete-modal-info {
  font-size: 14px;
}
</style>
